import React from 'react'
import { Modal } from "react-overlays";
import * as styles from './event-modal.module.scss';
import EventDetails from '../Event';

export default function EventModal({ showModal, handleClose, renderBackdrop, event }) {
  return (
    <Modal
      className={styles.modal}
      show={showModal}
      onHide={handleClose}
      renderBackdrop={renderBackdrop}
    >
      <div className={styles.modalContent} style={{ overflowY: 'auto' }}>
        <div>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              Event Details  
            </div>
            <div>
              <span className={styles.closeButton} onClick={handleClose}>x</span>
            </div>
          </div>

          <div className={styles.modalDesc}>
            <EventDetails event={event} />
          </div>
          
        </div>
      </div>
    </Modal>
  )
}
