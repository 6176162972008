import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaYoutube } from 'react-icons/fa'
import { PortableText } from "@portabletext/react"
import Showcase from "../components/Showcase"
import InfoBlock from "../components/InfoBlock"
import Statistics from "../components/Statistics"
import Footer from '../components/Layout/Footer'
import EventModal from "../components/EventModal"

// import * as styles from '../styles/pages/index-page.module.scss'

export const Head = () => (
  <>
    <title>GROWoneAFRICA&trade;</title>
    <meta name="description" content="South Africa's original service provider to cannabis social clubs and cultivators. Providing self-regulating compliance measures whilst growing cannabis culture & agri-economy." />
  </>
)

const AboutPage = ({ data }) => { // was HomePage
  const goaYoutubeChannel = data.sanityCompanyInfo.socialMediaLinks.youtube
  const {
    bannerImage,
    aboutUsSection,
    sloganDescriptionSection,
    meetTheTeamSection
  } = data.sanityAboutUsPage
  const events = data.allSanityEvents.nodes
  const [showModal, setShowModal] = useState(false)


  const filteredEvents = events.filter(event => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); 

    const displayFrom = new Date(event.displayFrom);
    const displayUntil = new Date(event.displayUntil);
    displayUntil.setHours(23, 59, 59, 999);
    
    return displayFrom <= now && displayUntil >= today;
  });

  // Get the most recent event
  const mostRecentEvent = filteredEvents[0];

  useEffect(() => {
    // Check if the modal has been shown this session
    const modalShown = sessionStorage.getItem('eventModalShown');
    
    // If there's an event and the modal hasn't been shown, show it
    if (mostRecentEvent && !modalShown) {
      setShowModal(true);
      // Mark the modal as shown for this session
      sessionStorage.setItem('eventModalShown', 'true');
    } 
  }, [mostRecentEvent]);

  return (
    <>
      <main>
        <Showcase image={bannerImage.asset} mainText={data.sanityAboutUsPage.mainText} subText={data.sanityAboutUsPage.subText} textVisible={data.sanityAboutUsPage.bannerTextVisible}/> {/* data.showcaseImage */}
        <InfoBlock
          featuredImage={
            <GatsbyImage image={getImage(aboutUsSection.featuredImage.asset)} alt="" />
          }
          id="next"
        >
          <h2>About Us</h2>
          <PortableText value={aboutUsSection._rawContent} />

          <a href={goaYoutubeChannel} target="_blank" rel="noreferrer" className="btn-primary">GOA Videos <FaYoutube /></a>
        </InfoBlock>
        <InfoBlock
          featuredImage={
            <GatsbyImage image={getImage(sloganDescriptionSection.featuredImage.asset)} alt="" />
          }
          reversed
        >
          <h2>GROW THE PEOPLE · NURTURE THE EARTH · AFRICA LEADS THE WAY</h2>
          <PortableText value={sloganDescriptionSection._rawContent} />
        </InfoBlock>
        <InfoBlock
          featuredImage={
            <GatsbyImage image={getImage(meetTheTeamSection.featuredImage.asset)} />
          }
        >
          <h2>Meet the Team</h2>
          <PortableText value={meetTheTeamSection._rawContent} />
          <Link to="/all-affiliates/" className="btn-primary">GOA Affiliates</Link>
        </InfoBlock>
      </main>
      <Footer>
        <Statistics />
      </Footer>
      {mostRecentEvent && (
        <EventModal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          renderBackdrop={() => {}}
          event={mostRecentEvent}
        />
      )}
    </>
  )
}

export const query = graphql`
  query IndexPageQuery {
    
    sanityAboutUsPage {
      bannerImage {
        asset {
          gatsbyImageData
        }
      }
      mainText
      subText
      bannerTextVisible

      aboutUsSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
      sloganDescriptionSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
      meetTheTeamSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
    }
    sanityCompanyInfo {
      socialMediaLinks {
        youtube
      }
    }
    allSanityEvents(sort: {fields: [displayFrom], order: DESC}) {
      nodes {
        id
        title
        description {
          _type
          style
          children {
            _key
            _type
            text
          }
        }
        featuredImage {
          asset {
            url
          }
        }
        showButton
        buttonText
        buttonLink
        eventOccurrences {
          startDate
          endDate
          locationName
        }
        displayFrom
        displayUntil
      }
    }
  }
`

export default AboutPage //was Homepage