// extracted by mini-css-extract-plugin
export var backdrop = "event-modal-module--backdrop--f238e";
export var closeButton = "event-modal-module--closeButton--086d7";
export var formContainer = "event-modal-module--formContainer--8c260";
export var memberInfo = "event-modal-module--memberInfo--2f7d6";
export var modal = "event-modal-module--modal--c7b56";
export var modalContent = "event-modal-module--modalContent--cb1eb";
export var modalDesc = "event-modal-module--modalDesc--38200";
export var modalExample = "event-modal-module--modalExample--5d8fb";
export var modalFooter = "event-modal-module--modalFooter--d717d";
export var modalHeader = "event-modal-module--modalHeader--ed782";
export var modalTitle = "event-modal-module--modalTitle--6a846";
export var primaryButton = "event-modal-module--primaryButton--59898";
export var secondaryButton = "event-modal-module--secondaryButton--4c448";
export var shopItem = "event-modal-module--shopItem--9446e";